import { MatomoTrackingModel } from '~/app/shared/types/envestboard-api';
import { TrackingEventContext } from '~/app/shared/types/tracking/tracking-event-context.type';

export abstract class BaseTrackingService {
    public abstract pushEvent(event: TrackingEventContext) :void;

    public abstract pageView() :void;

    public abstract updateUserId(userId: string | number): void ;

    public abstract insertScript(trackingSettings: MatomoTrackingModel | undefined): void;
}
