<eb-label
    class="button-link__label"
    [color]="color"
    [size]="size"
    [textDecoration]="textDecoration"
    [textTransform]="textTransform"
    [weight]="weight"
    (click)="buttonClick.emit()"
>
    <ng-content />
</eb-label>
