import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';

import {
    LabelComponent,
    LabelComponentColor,
    LabelComponentTextDecoration,
    LabelComponentTextTransform,
    LabelComponentWeight,
} from '~/app/shared/components/label/label.component';

export type ButtonLinkComponentSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

@Component({
    selector: 'eb-button-link',
    templateUrl: './button-link.component.html',
    styleUrls: ['./button-link.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [LabelComponent],
})
export class ButtonLinkComponent {
    @Input()
    public size: ButtonLinkComponentSize = 'sm';

    @Input()
    public weight: LabelComponentWeight = 'normal';

    @Input()
    public color: LabelComponentColor = 'primary';

    @Input()
    public textTransform: LabelComponentTextTransform = 'uppercase';

    @Input()
    public textDecoration: LabelComponentTextDecoration = 'normal';

    @Output()
    public buttonClick = new EventEmitter();
}
