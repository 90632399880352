export const ENVESTBOARD_GROUP_ID = 0;

export const DEFAULT_CURRENCY = 'EUR';

export const DEFAULT_LANGUAGE = 'FR';

export const CONTACTS_SIZE_LIST = 15;

export const CONTACTS_SEARCH_FIELDS = [
    'id',
    'firstName',
    'lastName',
    'email',
    'birthdate',
    'srri',
    'profession',
    'type',
    'groups',
    'totalAmount',
    'totalAmountConverted',
    'portfolioNumber',
    'createdDate',
    'permissions',
];
