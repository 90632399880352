import { FacetType } from '~/app/shared/enums/facet-type.enum';
import { AdvancedFilterBooleanValue } from '~/app/shared/types/advanced-filter/advanced-filter-boolean-value.type';
import { AdvancedFilterMultiBooleanValue } from '~/app/shared/types/advanced-filter/advanced-filter-multi-boolean-value.type';
import { AdvancedFilterMultiChoiceExtra } from '~/app/shared/types/advanced-filter/advanced-filter-multi-choice-extra.type';
import { AdvancedFilterRangeValue } from '~/app/shared/types/advanced-filter/advanced-filter-range-value.type';
import { AdvancedFilterSelectValue } from '~/app/shared/types/advanced-filter/advanced-filter-select-value.type';
import { AdvancedFilterValue } from '~/app/shared/types/advanced-filter/advanced-filter-value.type';
import { DefaultFacet } from '~/app/shared/types/facet/default-facet.type';
import { Facet } from '~/app/shared/types/facet/facet.type';


export function getShareDefaultFiltersProperties(): DefaultFacet[] {
    return [
        {
            property: 'assetUniverse',
            name: 'filter.property.assetUniverse',
            type: FacetType.MULTI_CHOICE,
        },
        {
            property: 'investGeography',
            name: 'filter.property.investGeography',
            type: FacetType.MULTI_CHOICE,
        },
        {
            property: 'investSector',
            name: 'filter.property.investSector',
            type: FacetType.MULTI_CHOICE,
        },
        {
            property: 'fundCategory',
            name: 'filter.property.category',
            type: FacetType.MULTI_CHOICE,
        },
        {
            property: 'srri',
            name: 'filter.property.srri',
            type: FacetType.RISK_PROFILE,
        },
        {
            property: 'isSustainableFinance',
            name: 'filter.property.sustainable_finance',
            type: FacetType.SUSTAINABLE_FINANCE,
        },
        {
            property: 'assuranceVies',
            name: 'filter.property.assuranceVies',
            type: FacetType.MULTI_CHOICE,
        },
        {
            property: 'assetManagerCompany',
            name: 'filter.property.assetManagerCompany',
            type: FacetType.MULTI_CHOICE,
        },
    ];
}

export function getPortfolioDefaultFiltersProperties(): DefaultFacet[] {
    return [
        {
            property: 'type',
            name: 'filter.property.portfolio_type',
            type: FacetType.MULTI_CHOICE,
        },
        {
            property: 'client',
            name: 'filter.property.client',
            type: FacetType.MULTI_CHOICE,
        },
        {
            property: 'srri',
            name: 'filter.property.srri',
            type: FacetType.RISK_PROFILE,
        },
        {
            property: 'assuranceVieWeights',
            name: 'filter.property.life_insurance',
            type: FacetType.MULTI_CHOICE,
        },
        {
            property: 'isSustainableFinance',
            name: 'filter.property.investment_responsible',
            type: FacetType.SUSTAINABLE_FINANCE,
        },
        {
            property: 'assetUniverseWeights',
            name: 'filter.property.asset_universe_expo',
            type: FacetType.MULTI_CHOICE,
        },
        {
            property: 'geographyWeights',
            name: 'filter.property.geography_expo',
            type: FacetType.MULTI_CHOICE,
        },
        {
            property: 'categoryWeights',
            name: 'filter.property.category_expo',
            type: FacetType.MULTI_CHOICE,
        },
    ];
}

export function getContactDefaultFiltersProperties(): DefaultFacet[] {
    return [
        {
            property: 'srri',
            name: 'filter.property.srri',
            type: FacetType.RISK_PROFILE,
        },
        {
            property: 'advisor.id',
            name: 'filter.property.advisor',
            type: FacetType.MULTI_CHOICE,
        },
    ];
}

export function getInitialDefaultFilters(defaultFacetsFilters: DefaultFacet[], facets: { [key: string]: Facet } = {}): AdvancedFilterValue[] {
    return defaultFacetsFilters.reduce((acc: AdvancedFilterValue[], defaultFacet): AdvancedFilterValue[] => {
        const facetValue = facets[defaultFacet.property];
        if (facetValue) {
            return [
                ...acc,
                {
                    uid: defaultFacet.property,
                    name: defaultFacet.name,
                    type: defaultFacet.type,
                    // eslint-disable-next-line no-nested-ternary
                    value: (defaultFacet.type === FacetType.BOOLEAN
                        ? {
                            property: defaultFacet.property,
                            value: false,
                        } as AdvancedFilterBooleanValue
                        // eslint-disable-next-line no-nested-ternary
                        : (defaultFacet.type === FacetType.RANGE ? {
                            property: defaultFacet.property,
                            min: null,
                            max: null,
                        } : (defaultFacet.type === FacetType.SUSTAINABLE_FINANCE ? {
                            property: defaultFacet.property,
                            properties: [],
                            exclude: false,
                            union: false,
                        } : {
                            property: defaultFacet.property,
                            values: [],
                            exclude: false,
                            union: true,
                        } as AdvancedFilterSelectValue))),
                    extra: {
                        include: facetValue.include,
                        intersect: facetValue.intersect,
                    },
                    // eslint-disable-next-line max-len
                } as AdvancedFilterValue<AdvancedFilterSelectValue | AdvancedFilterBooleanValue | AdvancedFilterRangeValue | AdvancedFilterMultiBooleanValue, AdvancedFilterMultiChoiceExtra>,
            ];
        }
        return [
            ...acc,
            {
                uid: defaultFacet.property,
                name: defaultFacet.name,
                type: FacetType.MULTI_CHOICE,
                value: {
                    property: defaultFacet.property,
                    values: [],
                    exclude: false,
                    union: true,
                } as AdvancedFilterSelectValue,
            }as AdvancedFilterValue<AdvancedFilterSelectValue>,
        ];
    }, []);
}
