import {
    ChangeDetectionStrategy,
    Component,
    Input,
} from '@angular/core';

import { LabelComponent } from '../label/label.component';

export type BadgeComponentSize = 'xs' | 'sm' | 'md' | 'lg';

@Component({
    selector: 'eb-badge',
    templateUrl: './badge.component.html',
    styleUrls: ['./badge.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [LabelComponent],
})
export class BadgeComponent {
    @Input()
        count = 0;

    @Input()
        size: BadgeComponentSize = 'xs';
}
