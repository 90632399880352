import { Injectable } from '@angular/core';
import {
    groupBy,
    omit,
} from 'ramda';
import { uid } from 'uid';

import { FacetType } from '~/app/shared/enums/facet-type.enum';
import { AdvancedFilterGeneric } from '~/app/shared/types/advanced-filter/advanced-filter-generic.type';
import { AdvancedFilterMultiRangeValue } from '~/app/shared/types/advanced-filter/advanced-filter-multi-range-value.type';
import { AdvancedFilterRiskIndicatorExtra } from '~/app/shared/types/advanced-filter/advanced-filter-risk-indicator-extra.type';
import { AdvancedFilterValueGeneric } from '~/app/shared/types/advanced-filter/advanced-filter-value-generic.type';
import { FacetFamily } from '~/app/shared/types/facet/facet-family.type';
import { Facet } from '~/app/shared/types/facet/facet.type';

@Injectable({
    providedIn: 'root',
})
export class AdvancedFiltersUtilsService {
    public formatFilters(
        filters: AdvancedFilterGeneric[],
        includeMetadata: boolean = false,
        promoted: boolean = false,
        facetsFamilies: FacetFamily[] = [],
    ): AdvancedFilterValueGeneric[] {
        const middlewares = [
            (data: AdvancedFilterGeneric[]) => this.formatSustainableFinanceExpo(data, includeMetadata),
            (data: AdvancedFilterGeneric[]) => this.formatBase(data, includeMetadata),
            (data: AdvancedFilterGeneric[]) => this.formatMultiChoice(data, includeMetadata),
            (data: AdvancedFilterGeneric[]) => this.formatRiskProfile(data, includeMetadata),
            (data: AdvancedFilterGeneric[]) => this.formatPromoted(data, promoted),
            (data: AdvancedFilterGeneric[]) => this.formatMultiBoolean(data, includeMetadata),
            (data: AdvancedFilterGeneric[]) => this.filterRiskIndicators(data),
            (data: AdvancedFilterGeneric[]) => this.filterExpo(data),
            (data: AdvancedFilterGeneric[]) => this.filterRange(data),
            (data: AdvancedFilterGeneric[]) => this.formatMinValues(data, facetsFamilies),
        ];

        const outputFilters = middlewares.reduce(
            (currentFilters: AdvancedFilterGeneric[], transform) => transform(currentFilters),
            filters,
        );

        return outputFilters
            .map((item) => ({
                ...item.value,
                ...(
                    includeMetadata
                        ? {
                            metadata: {
                                ...(item.value.metadata || {}),
                                filterId: item.uid,
                                filterFamilyField: item.familyField,
                            },
                        }
                        : {}
                ),
            }));
    }

    public filterRiskIndicators(filters: AdvancedFilterGeneric[]): AdvancedFilterGeneric[] {
        const riskIndicators = filters.filter((filter) => filter.type === FacetType.RISK_INDICATOR && (filter.value.min || filter.value.max));

        return [
            ...filters.filter((filter) => filter.type !== FacetType.RISK_INDICATOR),
            ...riskIndicators,
        ];
    }

    public filterExpo(filters: AdvancedFilterGeneric[]): AdvancedFilterGeneric[] {
        const expos = filters.filter((filter) => filter.type === FacetType.EXPO
        && ((filter.value.min !== null && filter.value.min !== undefined && filter.value.min >= 0)
            || (filter.value.max !== null && filter.value.max !== undefined && filter.value.max >= 0)) && filter.value.id);

        return [
            ...filters.filter((filter) => filter.type !== FacetType.EXPO),
            ...expos,
        ];
    }

    public filterRange(filters: AdvancedFilterGeneric[]): AdvancedFilterGeneric[] {
        const ranges = filters.filter((filter) => filter.type === FacetType.RANGE && (filter.value.min || filter.value.max));

        return [
            ...filters.filter((filter) => filter.type !== FacetType.RANGE),
            ...ranges,
        ];
    }

    public formatBase(filters: AdvancedFilterGeneric[], includeMetadata: boolean = false): AdvancedFilterGeneric[] {
        return filters
            .map((filter: AdvancedFilterGeneric<AdvancedFilterRiskIndicatorExtra>) => ({
                ...filter,
                ...(
                    filter.value
                        ? {
                            value: {
                                ...filter.value,
                                ...(
                                    includeMetadata
                                        ? {
                                            metadata: {
                                                filterFormat: filter.extra?.range?.format,
                                                filterDecimal: filter.extra?.range?.decimal,
                                                filterMin: filter.extra?.range?.min,
                                                filterMax: filter.extra?.range?.max,
                                            },
                                        }
                                        : {}
                                ),
                            },
                        }
                        : {}
                ),
            }));
    }

    public formatMultiChoice(filters: AdvancedFilterGeneric[], includeMetadata: boolean) : AdvancedFilterGeneric[] {
        const multiChoiceFilters = filters
            .filter(
                (filter) => filter.type === FacetType.MULTI_CHOICE && Array.isArray(filter.value?.values) && filter.value.values.length > 0,
            )
            .reduce(
                (acc, filter) => {
                    // eslint-disable-next-line max-len
                    const fields: { [fieldName: string]: string[] } = ((filter.value.values as string[]) || []).reduce((accFields: { [fieldName: string]: string[] }, filterValue: string) => {
                        const [fieldName, fieldValue] = filterValue.split('|');

                        return {
                            ...accFields,
                            [fieldName]: [...new Set([...(accFields[fieldName] || []), fieldValue])],
                        };
                    }, {} as { [key: string]: string[] });

                    return [
                        ...acc,
                        ...(
                            (Object.entries(fields) || [])
                                .map(([fieldName, fieldValues]: [string, string[]]) => ({
                                    name: filter.name,
                                    type: filter.type,
                                    familyField: filter.familyField,
                                    uid: filter.uid,
                                    value: {
                                        exclude: filter.value.exclude,
                                        property: fieldName,
                                        union: filter.value.union,
                                        values: fieldValues,
                                        ...(
                                            includeMetadata
                                                ? {
                                                    metadata: {
                                                        rootProperty: filter.value.property,
                                                    },
                                                }
                                                : {}
                                        ),
                                    },
                                }))
                        ),
                    ];
                }, [] as AdvancedFilterGeneric[],
            );

        const byField = groupBy((field: AdvancedFilterGeneric) => field.uid);

        const multiChoiceFiltersValues = Object.values(byField(multiChoiceFilters)) as AdvancedFilterGeneric[][];

        const groupedMultiChoices = multiChoiceFiltersValues.map((currentFilters: AdvancedFilterGeneric[]) => ({
            uid: currentFilters[0].uid,
            name: currentFilters[0].name,
            familyField: currentFilters[0].familyField,
            type: currentFilters[0].type,
            value: {
                properties: currentFilters.map((filter) => ({
                    property: filter.value.property,
                    values: filter.value.values,
                })),
                exclude: currentFilters[0].value.exclude,
                union: currentFilters[0].value.union,
                ...(
                    includeMetadata
                        ? {
                            metadata: {
                                rootProperty: currentFilters[0].value.metadata?.rootProperty,
                            },
                        }
                        : {}
                ),
            },
        }));

        return [
            ...filters.filter((filter) => filter.type !== FacetType.MULTI_CHOICE),
            ...groupedMultiChoices,
        ];
    }

    public formatRiskProfile(filters: AdvancedFilterGeneric[], includeMetadata: boolean) : AdvancedFilterGeneric[] {
        const riskProfileFilters = filters
            .filter(
                (filter) => filter.type === FacetType.RISK_PROFILE && Array.isArray(filter.value?.values) && filter.value.values.length > 0,
            )
            .map((filter) => ({
                uid: filter.uid,
                name: filter.name,
                familyField: filter.familyField,
                type: filter.type,
                value: {
                    properties: [{
                        property: filter.value.property,
                        values: filter.value.values,
                    }],
                    exclude: filter.value.exclude,
                    union: filter.value.union,
                    ...(
                        includeMetadata
                            ? {
                                metadata: {
                                    rootProperty: filter.value.property,
                                },
                            }
                            : {}
                    ),
                },
            }));

        return [
            ...filters.filter((filter) => filter.type !== FacetType.RISK_PROFILE),
            ...riskProfileFilters,
        ];
    }

    public formatMultiBoolean(filters: AdvancedFilterGeneric[], includeMetadata: boolean) : AdvancedFilterGeneric[] {
        const multiBooleanFilters = filters
            .filter(
                (filter) => filter.type === FacetType.SUSTAINABLE_FINANCE && Array.isArray(filter.value?.properties) && filter.value.properties.length > 0,
            )
            .map(
                (filter) => ({
                    uid: filter.uid,
                    name: filter.name,
                    type: filter.type,
                    familyField: filter.familyField,
                    value: {
                        exclude: filter.value.exclude,
                        union: filter.value.union,
                        properties: filter.value.properties,
                        ...(
                            includeMetadata
                                ? {
                                    metadata: {
                                        rootProperty: filter.value.property,
                                    },
                                }
                                : {}
                        ),
                    },
                } as AdvancedFilterGeneric),
            );

        return [
            ...filters.filter((filter) => filter.type !== FacetType.SUSTAINABLE_FINANCE),
            ...multiBooleanFilters,
        ];
    }

    public formatMinValues(filters: AdvancedFilterGeneric[], facetFamilies: FacetFamily[]): AdvancedFilterGeneric[] {
        const facetsByKey = this.getFacetsByKey(facetFamilies);

        const ranges = filters
            .filter((filter) => (
                filter.type === FacetType.RANGE
                || filter.type === FacetType.EXPO
                || filter.type === FacetType.RANGE_PERCENTAGE
            ))
            .map((filter) => {
                const facetFound = facetsByKey[filter.uid];

                let min: number | undefined;

                if (facetFound && Array.isArray(facetFound.values)) {
                    const foundValue = facetFound.values.find((item) => item.value === filter.value.id);
                    min = foundValue?.min;
                } else if (facetFound && facetFound.min) {
                    min = facetFound.min;
                }

                return {
                    ...filter,
                    value: {
                        ...filter.value,
                        ...(
                            typeof filter.value.min === 'number'
                                ? {
                                    min: min && filter.value.min < min ? min : filter.value.min,
                                }
                                : {}
                        ),
                    },
                };
            });

        return [
            ...filters.filter((filter) => (
                filter.type !== FacetType.RANGE
                && filter.type !== FacetType.EXPO
                && filter.type !== FacetType.RANGE_PERCENTAGE
            )),
            ...ranges,
        ];
    }

    public formatSustainableFinanceExpo(filters: AdvancedFilterGeneric[], includeMetadata: boolean) : AdvancedFilterGeneric[] {
        const sustainableFilters = filters
            .filter(
                (filter) => filter.type === FacetType.SUSTAINABLE_FINANCE_EXPO && Array.isArray(filter.value?.ranges),
            )
            .reduce((acc: AdvancedFilterGeneric[], filter) => [...(filter.value as AdvancedFilterMultiRangeValue).ranges.map((val) => ({
                uid: filter.uid,
                name: filter.name,
                type: filter.type,
                familyField: filter.familyField,
                value: {
                    ...val,
                    ...(
                        includeMetadata
                            ? {
                                metadata: {
                                    rootProperty: filter.value.property,
                                },
                            }
                            : {}
                    ),
                },
            } as AdvancedFilterGeneric))], []);
        return [
            ...filters.filter((filter) => filter.type !== FacetType.SUSTAINABLE_FINANCE_EXPO),
            ...sustainableFilters,
        ];
    }

    public formatPromoted(filters: AdvancedFilterGeneric[], promoted = false) {
        if (!promoted) {
            return filters;
        }
        return filters.filter(
            (item) => ![
                FacetType.BOOLEAN,
                FacetType.RANGE,
                FacetType.RANGE_PERCENTAGE,
                FacetType.RISK_INDICATOR,
                FacetType.RISK_INDICATORS,
                FacetType.NOTATIONS,
                FacetType.PRICE,
                FacetType.SUSTAINABLE_FINANCE,
            ].includes(item.type),
        );
    }

    public parseFilters(filters: Array<AdvancedFilterValueGeneric>, facetFamilies: FacetFamily[]): AdvancedFilterGeneric[] {
        return filters.reduce((prev, item) => [
            ...prev,
            ...(
                item.property || item.properties
                    ? [
                        this.generateFilterFromFacet(item, facetFamilies),
                    ] as AdvancedFilterGeneric[]
                    : [] as AdvancedFilterGeneric[]
            ),
            ...(
                item.code
                    ? [
                        this.generateFilterFromRiskIndicator(item),
                    ] as AdvancedFilterGeneric[]
                    : [] as AdvancedFilterGeneric[]
            ),
        ], [] as AdvancedFilterGeneric[]);
    }

    public getFacetsByKey(facetsFamily: FacetFamily[]) {
        return facetsFamily
            .reduce((acc, item) => [
                ...acc,
                ...item.values,
            ], [] as Facet[])
            .reduce((acc, item) => ({
                ...acc,
                [item.field]: item,
            }), {} as { [key: string]: Facet });
    }


    private generateFilterFromFacet(item: AdvancedFilterValueGeneric, facetFamilies: FacetFamily[]): AdvancedFilterGeneric {
        if (!item.property && !item.properties) {
            return {} as AdvancedFilterGeneric;
        }
        const facet = this.findFacet(item.metadata?.rootProperty || item.property as string, facetFamilies);
        if ((item.property ?? item.metadata?.filterId) === 'srri' || (item.property ?? item.metadata?.filterId) === 'srriCalculated') {
            return {
                uid: item.metadata?.filterId || uid(),
                name: facet.name,
                type: FacetType.RISK_PROFILE,
                familyField: item.metadata?.filterFamilyField,
                value: {
                    ...omit(['metadata'], item),
                    property: item.properties?.[0]?.property,
                    values: item.properties?.[0]?.values,
                },
                extra: {
                    include: !!facet.include,
                    intersect: !!facet.intersect,
                },
            };
        }
        return {
            uid: item.metadata?.filterId || uid(),
            name: facet.name,
            type: facet.field === 'isSustainableFinance' ? FacetType.SUSTAINABLE_FINANCE : facet.type || FacetType.MULTI_CHOICE,
            familyField: item.metadata?.filterFamilyField,
            value: {
                ...omit(['metadata'], item),
                property: item.metadata?.rootProperty || item.property || item.metadata?.filterId,
                values: (item.properties ? (item.properties || []).reduce((acc: string[], propertyValue) => [
                    ...acc,
                    ...(propertyValue.values || []).map((value) => `${propertyValue.property || ''}|${value}`),
                ], [])
                    : (item.values || []).map((value) => `${item.property || ''}|${value}`)),
            },
            extra: {
                include: !!facet.include,
                intersect: !!facet.intersect,
            },
        };
    }

    private generateFilterFromRiskIndicator(item: AdvancedFilterValueGeneric): AdvancedFilterGeneric {
        return {
            uid: uid(),
            name: item.name,
            type: item.period ? FacetType.RISK_INDICATORS : FacetType.NOTATIONS,
            familyField: item.metadata?.filterFamilyField,
            value: {
                code: item.code,
                period: item.period,
                benchmark: item.benchmark,
                marketCycle: item.marketCycle,
                min: item.min,
                max: item.max,
            },
        } as AdvancedFilterGeneric;
    }

    private findFacet(property: string, facetFamilies: FacetFamily[]): Facet {
        const mergedFacets = facetFamilies.reduce((acc: Facet[], curr: FacetFamily): Facet[] => {
            const facets = curr.values;

            return [
                ...acc,
                ...facets,
            ];
        }, []);

        const foundFacet = mergedFacets.find((facet) => facet.field === property);

        if (foundFacet) {
            return foundFacet;
        }

        return {} as Facet;
    }
}
